.create-group-container {
  width: 100%;
  max-width: 1600px;  
  margin: 40px auto;
  padding: 40px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.create-group-container h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #3c91e6;
  font-weight: bold;
}

.create-group-container h3 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #3c91e6;
}

.subtitle {
  color: #666;
  margin-bottom: 2rem;
}

.form-card {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.group-profile {
  margin-bottom: 2rem;
}

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.upload-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
  border: 2px dashed #d1d5db;
}

.upload-circle span {
  font-size: 2rem;
  color: #9ca3af;
}

.upload-circle img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.basic-info h2,
.meeting-schedule h2,
.topics-section h2,
.privacy-settings h2 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1rem;
}
.privacy-settings {
  margin: 2rem 0;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  color: #4b5563;
  cursor: pointer;
  padding: 0.5rem 0;
}

.radio-group input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #d1d5db;
  border-radius: 50%;
  margin: 0;
  cursor: pointer;
  position: relative;
}

.radio-group input[type="radio"]:checked {
  border-color: #3b82f6;
}

.radio-group input[type="radio"]:checked::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #3b82f6;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.radio-group label:hover input[type="radio"] {
  border-color: #3b82f6;
}

input[type="text"],
input[type="number"],
textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  margin-bottom: 1rem;
  font-size: 1rem;
}

textarea {
  height: 100px;
  resize: vertical;
}

.two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.create-group-btn {
  width: 100%;
  padding: 1rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-group-btn:hover {
  background-color: #2563eb;
}

.required-fields {
  margin-top: 1rem;
  color: #6b7280;
  font-size: 0.875rem;
  text-align: center;
}

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.image-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.upload-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #f3f4f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px dashed #d1d5db;
  position: relative;
  overflow: hidden;
}

.upload-circle:hover {
  background-color: #e5e7eb;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #fee2e2;
  color: #dc2626;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.remove-image-btn:hover {
  background-color: #fecaca;
}

.remove-image-btn svg {
  width: 16px;
  height: 16px;
}

.plus-icon {
  font-size: 2rem;
  color: #9ca3af;
  margin-bottom: 0.5rem;
}

.upload-hint {
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 0.5rem;
}

@media (max-width: 640px) {
  .two-columns {
    grid-template-columns: 1fr;
  }

  .create-group-container {
    padding: 1rem;
  }

  .form-card {
    padding: 1.5rem;
  }
}


@media (min-width: 1440px) {
  .create-group-container {
      max-width: 1400px;
  }
}

@media (max-width: 768px) {
  .create-group-container {
      margin: 20px auto;
      padding: 20px;
  }
}