.learn-mode-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    min-height: calc(100vh - 100px);
}

.learn-mode-page h1 {
    font-size: 2.5rem;
    color: #3c91e6;
    text-align: center;
    margin-bottom: 2rem;
}

.flashcard-sets {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 1rem;
}

.set-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
}

.set-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.set-card h3 {
    color: #333;
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
}

.set-card p {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    line-height: 1.4;
}

.set-info {
    display: flex;
    justify-content: space-between;
    padding-top: 0.75rem;
    border-top: 1px solid #eee;
    color: #888;
    font-size: 0.8rem;
}

.flashcard-study {
    max-width: 900px;
    margin: 0 auto;
    padding: 2rem;
}

.study-header {
    text-align: center;
    margin-bottom: 2rem;
    position: relative;
    padding: 2rem 0;
    border-bottom: 1px solid #eee;
}

.study-header h2 {
    font-size: 2rem;
    color: #3c91e6;
    margin: 0.5rem 0;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0.5px;
}

.progress {
    color: #666;
    font-size: 0.9rem;
}

.back-button {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #3c91e6;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.back-button:hover {
    background: rgba(60, 145, 230, 0.1);
}

.flashcard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.flashcard {
    width: 100%;
    height: 400px;
    perspective: 1000px;
    cursor: pointer;
}

.flashcard-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flashcard.flipped .flashcard-inner {
    transform: rotateY(180deg);
}

.flashcard-front,
.flashcard-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.flashcard-front p,
.flashcard-back p {
    font-size: 1.5rem;
    line-height: 1.6;
    color: #333;
}

.flashcard-back {
    transform: rotateY(180deg);
    background: #f8f9fa;
}

.navigation-controls {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.nav-button {
    padding: 0.75rem 1.5rem;
    background-color: #3c91e6;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
    min-width: 120px;
}

.nav-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.7;
}

.nav-button:not(:disabled):hover {
    background-color: #2a7bd7;
    transform: translateY(-2px);
}

.study-tips {
    text-align: center;
    color: #666;
    font-size: 0.9rem;
    font-style: italic;
}

.empty-sets {
    text-align: center;
    background: white;
    padding: 3rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    max-width: 600px;
}

.empty-sets p {
    color: #666;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.create-button {
    padding: 0.75rem 1.5rem;
    background-color: #3c91e6;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.create-button:hover {
    background-color: #2a7bd7;
    transform: translateY(-2px);
}

.loading,
.error-message {
    text-align: center;
    padding: 3rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    max-width: 600px;
}

.error-message {
    background: #fff5f5;
}

.error-message p {
    color: #e53e3e;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
}

.retry-button {
    padding: 0.75rem 1.5rem;
    background-color: #3c91e6;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.retry-button:hover {
    background-color: #2a7bd7;
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .learn-mode-page {
        padding: 1rem;
    }

    .flashcard {
        height: 300px;
    }

    .flashcard-front p,
    .flashcard-back p {
        font-size: 1.2rem;
    }

    .navigation-controls {
        flex-direction: column;
        width: 100%;
    }

    .nav-button {
        width: 100%;
    }

    .back-button {
        position: static;
        transform: none;
        margin-bottom: 1rem;
        justify-content: center;
    }

    .study-header {
        margin-top: 1rem;
    }
}