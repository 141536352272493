.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f9;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #3c91e6);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
    mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(1turn);
    }
}

.loading-container {
    animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}