.edit-test-page {
    width: 100%;
    max-width: 1000px;
    margin: 40px auto;
    padding: 40px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.edit-test-page h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 30px;
}

.edit-test-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

label {
    font-size: 1rem;
    color: #333;
    margin-bottom: 10px;
}

input,
textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 1rem;
    margin-bottom: 15px;
}

.description-input {
    min-height: 120px;
    resize: vertical;
}

button {
    padding: 12px 20px;
    font-size: 1rem;
    background-color: #3c91e6;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 20px;
}

button:hover {
    background-color: #2a7bd7;
}

.add-question-button,
.remove-question-button {
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ddd;
}

.add-question-button:hover,
.remove-question-button:hover {
    background-color: #e0e0e0;
}

.edit-test-actions {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 40px;
}