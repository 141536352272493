.pageContainer {
    min-height: 100vh;
    background-color: #f4f4f9;
    display: flex;
    align-items: center;
}

.mainGrid {
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 6rem; 
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.leftColumn {
    display: grid;
    gap: 4rem; 
}

.brandSection {
    text-align: center;
}

.mainHeading {
    font-size: 5.5rem;
    font-weight: bold;
    color: #3c91e6;
    margin: 0;
    opacity: 0;
    transform: translateY(-20px);
    animation: slideIn 1.5s ease-out forwards;
}

.mainParagraph {
    font-size: 2rem;
    color: #555;
    margin-top: 0.5rem;
    opacity: 0;
    animation: textReveal 1.5s ease-out forwards 0.5s;
    font-family: 'Inter', sans-serif; 
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes textReveal {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
.welcomeSection {
    text-align: center;
    padding: 2rem;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.welcomeHeading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1.5rem;
    font-family: 'Playfair Display', serif;
    font-weight: 600;
}

.welcomeText {
    font-size: 1.5rem;
    color: #555;
    line-height: 1.6;
    font-family: 'Inter', sans-serif;
    max-width: 500px;
    margin: 0 auto;
}

.welcomeSignUp {
    display: inline-block;
    margin-top: 1.5rem;
    color: #3c91e6;
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.welcomeSignUp:hover {
    transform: translateY(-2px);
    color: #2c7ed6;
}

.rightColumn {
    display: flex;
    align-items: center;
}

.form {
    background: white;
    padding: 2.5rem;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    width: 100%;
}

.heading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2rem;
}

.input {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border-radius: 4px;
    border: 1px solid #8d8b8b !important;
    outline: none;
    font-size: 1rem;
}

.input:focus {
    border-color: #3c91e6;
    box-shadow: 0 0 5px rgba(60, 145, 230, 0.5);
}

.primaryButton {
    width: 100%;
    padding: 0.75rem;
    margin-top: 1rem;
    border-radius: 4px;
    border: none;
    background-color: black;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
}

.toggleText {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #555;
}

.toggleLink {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}

.divider {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
}

.dividerLine {
    flex: 1;
    height: 1px;
    background-color: #ddd;
}

.dividerText {
    margin: 0 0.5rem;
    font-size: 0.8rem;
    color: #888;
}

.socialButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    font-size: 1rem;
    color: #555;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.error {
    color: red;
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

@media (max-width: 1024px) {
    .mainGrid {
        grid-template-columns: 1fr;
        gap: 3rem;
        padding: 1rem;
    }

    .welcomeHeading {
        font-size: 2.8rem;
    }

    .welcomeText {
        font-size: 1.3rem;
    }
}

.password-criteria {
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
    color: #e53e3e;
    font-size: 0.875rem;
}

.password-criteria p {
    margin-bottom: 5px;
    font-weight: 500;
}

.password-criteria ul {
    list-style-type: none;
    margin: 0;
    padding-left: 10px;
}

.password-criteria li {
    margin: 3px 0;
}