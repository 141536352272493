.sidebar {
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;  
    height: 100vh;
    padding: 30px; 
    background-color: #f6f6f6;
    color: #000000;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.sidebar.opening {
    transform: translateX(0);
}

.sidebar.closing {
    transform: translateX(100%);
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;
}

button {
    transition: transform 0.2s ease, background-color 0.3s ease;
}


.closeButton {
    position: absolute;
    top: 20px; 
    right: 20px; 
    width: 36px; 
    height: 36px; 
    background-color: rgba(236, 240, 241, 0.1);
    border: none;
    border-radius: 50%;
    font-size: 1.2rem;
    cursor: pointer;
    color: #0d0d0d;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.closeButton:hover {
    background-color: rgba(236, 240, 241, 0.2);
    transform: rotate(90deg);
}

.profileContainer {
    margin-top: 40px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.sidebar:hover .profileContainer {
    transform: scale(1.05); 
}

.profilePictureContainer {
    position: relative;
    width: 150px;  
    height: 150px; 
    border-radius: 50%;
    overflow: hidden;
    background-color: #3c91e6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 25px; 
    transition: transform 0.3s ease;
}

.sidebar:hover .profilePictureContainer {
    transform: scale(1.05); 
}

.addIcon {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fileInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.editContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 0 10px;
}

.input {
    width: 100%;
    padding: 12px;
    margin: 0.5rem 0;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;
    font-size: 1rem;
    color: #444;
    background-color: #fafafa;
    transition: all 0.3s ease;
}

.input:focus {
    border-color: #3c91e6;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(60, 145, 230, 0.1);
}

.saveButton {
    width: 100%;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    background-color: #f7fcf7;
    color: #2ecc71;
    box-shadow: 
        0 2px 4px rgba(46, 204, 113, 0.05),
        inset 0 1px 0 rgba(255, 255, 255, 0.5);
    transition: all 0.3s ease;
    font-weight: 500;
    margin-bottom: 8px;
}

.saveButton:hover {
    background-color: #f9fdf9;
    transform: translateY(-1px);
    box-shadow: 
        0 4px 8px rgba(46, 204, 113, 0.1),
        inset 0 1px 0 rgba(255, 255, 255, 0.5);
}

.saveButton:active {
    transform: translateY(1px);
    box-shadow: 
        0 2px 4px rgba(46, 204, 113, 0.05),
        inset 0 1px 0 rgba(255, 255, 255, 0.5);
}

.cancelButton {
    width: 100%;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    background-color: #fff5f5;
    color: #d63031;
    box-shadow: 
        0 2px 4px rgba(214, 48, 49, 0.05),
        inset 0 1px 0 rgba(255, 255, 255, 0.5);
    transition: all 0.3s ease;
    font-weight: 500;
}

.cancelButton:hover {
    background-color: #fff8f8;
    transform: translateY(-1px);
    box-shadow: 
        0 4px 8px rgba(214, 48, 49, 0.1),
        inset 0 1px 0 rgba(255, 255, 255, 0.5);
}

.cancelButton:active {
    transform: translateY(1px);
    box-shadow: 
        0 2px 4px rgba(214, 48, 49, 0.05),
        inset 0 1px 0 rgba(255, 255, 255, 0.5);
}


.navLinks {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.editButton {
    background-color: #fafafa;
    color: #444;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    box-shadow: 
        0 2px 4px rgba(0, 0, 0, 0.05),
        inset 0 1px 0 rgba(255, 255, 255, 0.5);
    transition: all 0.3s ease;
    font-weight: 500;
}

.editButton:hover {
    background-color: #ffffff;
    transform: translateY(-1px);
    box-shadow: 
        0 4px 8px rgba(0, 0, 0, 0.1),
        inset 0 1px 0 rgba(255, 255, 255, 0.5);
}

.logoutButton {
    background-color: #fff5f5;
    color: #d63031;
    padding: 12px;
    border: 1px solid rgba(214, 48, 49, 0.2);
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    box-shadow: 
        0 2px 4px rgba(214, 48, 49, 0.05),
        inset 0 1px 0 rgba(255, 255, 255, 0.5);
    transition: all 0.3s ease;
    font-weight: 500;
}

.logoutButton:hover {
    background-color: #fff8f8;
    transform: translateY(-1px);
    box-shadow: 
        0 4px 8px rgba(214, 48, 49, 0.1),
        inset 0 1px 0 rgba(255, 255, 255, 0.5);
}

.profileText {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.profileText p {
    font-style: italic;
    color: #030303;
}

.streak-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: 15px 0 30px 0;
    color: #ecf0f1;
}

.streak {
    font-size: 0.9rem;
    line-height: 1.4;
    margin: 0;
}

.streak-welcome {
    color: #000000; 
    font-weight: 500;
}

@keyframes welcomePulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

.first-login .streak-container span[role="img"] {
    animation: welcomePulse 1s infinite;
}


.name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;

}

.bio {
    font-size: 1rem;
    color: #ecf0f1;
    font-style: italic;
}
