.page-transition {
    animation: fadeIn 0.3s ease-out;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(8px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .nav-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
  
  .nav-links {
    display: flex;
    gap: 20px;
  }
  
  .nav-links a {
    position: relative;
    color: #333;
    text-decoration: none;
    padding: 10px 15px;
    transition: color 0.3s ease;
}
  
.nav-links a::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #3c91e6;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}
  
  .nav-links a:hover {
    color: #3c91e6;
  }
  
  .nav-links a:hover::after {
    transform: scaleX(1);
}

.nav-links a.active {
    color: #3c91e6;
}

.nav-links a.active::after {
    transform: scaleX(1);
}