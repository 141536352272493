.about-page {
    width: 100%;
    max-width: 1600px;  
    margin: 40px auto;
    padding: 40px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.about-page h1 {
    font-size: 2.5em;
    color: #3c91e6;
    margin-bottom: 40px;
    font-weight: bold;
}

.profile-section {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.profile {
    background: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile:hover {
    transform: translateY(-5px);
}

.profile-image-container {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(60, 145, 230, 0.2);
    border: 3px solid #3c91e6;
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-content {
    text-align: center;
}

.profile h2 {
    color: #3c91e6;
    font-size: 1.5em;
    margin-bottom: 12px;
}

.profile p {
    color: #555;
    font-size: 1.1em;
    line-height: 1.5;
    max-width: 600px;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .about-page {
        padding: 20px;
    }

    .profile {
        padding: 20px;
    }

    .profile-image-container {
        width: 120px;
        height: 120px;
    }

    .profile p {
        font-size: 1em;
    }
}

@media (min-width: 1440px) {
    .about-page {
        max-width: 1400px;
    }
  }
  
  @media (max-width: 768px) {
    .about-page {
        margin: 20px auto;
        padding: 20px;
    }
  } 