
.chat-container {
    display: grid;
    grid-template-columns: 250px 1fr 250px;
    height: calc(100vh - 64px);
    background: #f8f9fa;
    gap: 1px;
    margin-top: 0px; 
    position: relative; 
  }
  
  .chat-sidebar {
    background: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

  }
  
  .chat-header {
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    flex-shrink: 0;
    margin-top: 20px;
    text-align: center;
  }
  
  .members-section,
  .resources-section,
  .events-section {
    padding: 1.5rem;
    background: white;
  }
  
  .members-list,
  .resources-list,
  .events-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    overflow-y: auto;
  }
  
  .chat-main {
    display: flex;
    flex-direction: column;
    background: white;
    height: 100%;
  }
  
  .messages-container {
    flex: 1;
    padding: 1.5rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-image: url('/public/background.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  
  .message-input {
    padding: 1.5rem;
    border-top: 1px solid #e9ecef;
    background: white;
    width: 100%;
  }
  
  .messages-container::-webkit-scrollbar,
  .chat-sidebar::-webkit-scrollbar {
    width: 6px;
  }
  
  .messages-container::-webkit-scrollbar-track,
  .chat-sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .messages-container::-webkit-scrollbar-thumb,
  .chat-sidebar::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 3px;
  }
  
  .messages-container::-webkit-scrollbar-thumb:hover,
  .chat-sidebar::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
  
  .chat-sidebar h2 {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  
  .member-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem;
    border-radius: 6px;
    transition: background-color 0.2s;
  }

  .resource-item {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    border-radius: 8px;
    background: white;
    transition: all 0.2s ease;
    cursor: pointer;
    border: 1px solid #edf2f7;
    cursor: context-menu;
}

.resource-item:hover {
  background: #f8f9fa;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.resource-icon {
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.resource-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resource-name {
  font-size: 0.9rem;
  color: #2d3748;
}
  
  .member-item:hover {
    background-color: #f8f9fa;
  }
  
  .member-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #e9ecef;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .avatar-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #666;
    background-color: #e9ecef;
  }
  
  .member-name {
    font-size: 0.9rem;
    color: #495057;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .owner-crown {
      color: #fbbf24;
  }
  
  .chat-header h1 {
    font-size: 2rem;
    color: #3c91e6;
    margin: 0;
    text-align: center;
  }
  
  .chat-header p {
    display: none;
  }

  .message {
    display: flex;
    margin-bottom: 0.5rem;
    max-width: 80%;
  }
  
  .message.own-message {
    margin-left: auto;
  }

  .own-message .message-content {
    background-color: rgba(163, 201, 239, 0.9);
    margin-left: auto; 
  }
  
  .message-content {
    background-color:rgba(255, 255, 255, 0.9);
    padding: 0.75rem 1rem;
    border-radius: 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .message p {
    margin: 0;
    color: #333;
    line-height: 1.4;
  }

  .message-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
  }
  
  .sender-name {
    font-weight: 600;
    color: #333;
  }


  .own-message .sender-name {
    color: #305d8a;
  }
  
  .timestamp {
    font-size: 0.75rem;
    color: #666;
  }
  
  .message-content p {
    margin: 0;
    color: #333;
    word-wrap: break-word;
  }
  
  .input-container {
    display: flex;
    gap: 0.75rem;
  }

  .message-content:hover {
  background-color: rgba(255, 255, 255, 1.2);
}

.own-message .message-content:hover {
  background-color: rgba(142, 192, 242, 0.9);;
}
  
  
  .message-input input[type="text"] {
    flex: 1;
    padding: 0.75rem 1rem;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    font-size: 0.95rem;
    outline: none;
    transition: border-color 0.2s;
  }
  
  .message-input input[type="text"]:focus {
    border-color: #3c91e6;
  }
  
  .message-input button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 6px;
    background: #3c91e6;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .message-input button:hover {
    background: #2a7bd7;
  }
  
  .message-input button:disabled {
    background: #e9ecef;
    cursor: not-allowed;
  }
  
  .file-input {
    display: none;
  }
  
  .attachment-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    font-size: 0.85rem;
    color: #6c757d;
  }
  
  .attachment-image {
    max-width: 300px;
    max-height: 200px;
    border-radius: 8px;
  }

  .attachment-preview {
    margin-top: 0.5rem;
  }
  
  .attachment-preview img {
    max-width: 300px;
    max-height: 200px;
    object-fit: contain;
  }
  
  .attachment-preview button {
    padding: 0.25rem 0.5rem;
    background: #dc3545;
  }
  
  .event-item {
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    cursor: pointer;
  }
  
  .event-item:hover {
    background: #e9ecef;
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .event-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }
  
  .event-time {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.85rem;
    color: #6c757d;
    margin-left: 1.75rem;
  }

  .event-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
  }
  
  .file-download {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    color: #3c91e6;
    text-decoration: none;
    transition: background-color 0.2s;
  }

  .download-btn {
    padding: 0.5rem;
    border-radius: 6px;
    background: none;
    border: none;
    color: #3c91e6;
    cursor: pointer;
    transition: all 0.2s ease;
}

.download-btn:hover {
    background: rgba(60, 145, 230, 0.1);
}
  
  .file-download:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
  }
  
  .modal h2 {
    margin-bottom: 1.5rem;
  }
  
  .modal form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .modal input[type="text"] {
    padding: 0.75rem;
    border: 1px solid #dee2e6;
    border-radius: 6px;
  }

.modal input[type="date"],
.modal input[type="time"] {
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  width: 100%;
}
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .modal-buttons button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }

  .modal-buttons button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .modal-buttons button[type="button"] {
    background: #f8f9fa;
    color: #495057;
  }
  
  .modal-buttons button[type="submit"] {
    background: #3c91e6;
    color: white;
  }
  
  .section-header {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    background: white;
  }
  
  
  .add-button {
    background: none;
    border: none;
    color: #3c91e6;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .add-button:hover {
    background: #e3f2fd;
  }
  
  @media (max-width: 1024px) {
    .chat-container {
      grid-template-columns: 200px 1fr;
    }
    
    .chat-sidebar:last-child {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .chat-container {
      grid-template-columns: 1fr;
    }
    
    .chat-sidebar {
      display: none;
    }
  }

  .date-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    position: relative;
  }
  
  .date-separator span {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0.5rem 1rem;
    border-radius: 16px;
    font-size: 0.8rem;
    color: #666;
    z-index: 1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .settings-container {
    position: relative;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
  }
  
  .settings-button {
    background: none;
    border: none;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    color: #666;
    transition: background-color 0.2s;
  }
  
  .settings-button:hover {
    background-color: #f8f9fa;
  }
  
  .settings-dropdown {
    position: absolute;
    top: 100%;
    left: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    padding: 0.5rem;
    z-index: 100;
    min-width: 200px;
  }
  
  .settings-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem;
    width: 100%;
    border: none;
    background: none;
    cursor: pointer;
    color: #333;
    border-radius: 4px;
    text-align: left;
  }
  
  .settings-option:hover {
    background-color: #f8f9fa;
  }
  
  .settings-option.leave-group {
    color: #dc3545;
  }
  
  .settings-option.leave-group:hover {
    background-color: #fff5f5;
  }
  
  .hidden {
    display: none;
  }

  .context-menu, .event-context-menu {
    position: fixed;
    background: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 0.5rem;
    z-index: 1000;
    min-width: 150px;
}

.event-context-menu .context-menu-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  color: #333;
}

.event-context-menu .context-menu-item:hover {
  background: #f5f5f5;
}

.event-context-menu .context-menu-item.delete-option {
  color: #dc2626;
}

.event-context-menu .context-menu-item.delete-option:hover {
  background: #fee2e2;
}