.flashcard-creator {
  width: 100%;
  max-width: 1600px;  
  margin: 40px auto;
  padding: 40px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.flashcard-creator h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #3c91e6;
  font-weight: bold;
}

.creator-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title-input,
.description-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.description-input {
  min-height: 100px;
  resize: vertical;
}

.tool-buttons {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
}

.tool-btn {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: white;
  color: #666;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.2s ease;
}

.tool-btn:hover {
  border-color: #3c91e6;
  color: #3c91e6;
}

.flashcard-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.card-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #666;
  font-size: 0.9rem;
  margin-right: 1rem;
}

.cancel-button {
  padding: 10px 20px;
  background-color: #f5f5f5;
  color: #424242;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.cancel-button:hover {
  background-color: #e0e0e0;
}

.card-content {
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: stretch;
}

.delete-icon {
  display: flex;
  align-self: center;
  justify-content: center;
  background: none;
  border: none;
  color: #dc2626;
  cursor: pointer;
  padding: 12px; 
  border-radius: 6px;
  transition: all 0.2s ease;
  margin-left: 4px;
  margin-top: -13px;
}

.delete-icon:hover:not(:disabled) {
  background-color: #fee2e2;
}

.delete-icon:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.delete-icon svg {
  width: 20px;
  height: 20px;
}

.card-inputs {
  display: flex;
  gap: 1rem;
  flex: 1;
}

.term-input,
.definition-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 100%;
  transition: border-color 0.2s ease;
  align-self: center;
}

.term-input:focus,
.definition-input:focus {
  border-color: #3c91e6;
  outline: none;
}

.image-btn {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: white;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
}

.image-btn:hover {
  border-color: #3c91e6;
  color: #3c91e6;
}

.add-card-btn {
  padding: 12px;
  border: 2px dashed #ddd;
  border-radius: 8px;
  background: white;
  color: #3c91e6;
  cursor: pointer;
  margin: 20px 0;
  transition: all 0.2s ease;
}

.add-card-btn:hover {
  border-color: #3c91e6;
  background: #f8f9fa;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

.create-btn {
  padding: 10px 20px;
  background-color: #e3f2fd;
  color: #1976d2;
  border: 1px solid #bbdefb;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.create-btn:hover {
  background-color: #bbdefb;
}

.cancel-button:hover,
.create-btn:hover,
.create-practice-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.create-practice-btn {
  padding: 10px 20px;
  background-color: #3c91e6;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.create-practice-btn:hover {
  background-color: #2a7bd7;
}

.create-btn:disabled,
.create-practice-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.create-btn:disabled:hover,
.create-practice-btn:disabled:hover {
  background: inherit;
  transform: none;
}


@media (min-width: 1440px) {
  .flashcard-creator {
      max-width: 1400px;
  }
}

@media (max-width: 768px) {
  .flashcard-creator {
      margin: 20px auto;
      padding: 20px;
  }
}