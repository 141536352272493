.popup-notification {
  position: fixed;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f5f6f8;
  color: white;
  padding: 15px 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  z-index: 9999;
  animation: slideInOut 4s ease-in-out forwards;
}

@keyframes slideInOut {
  0% { 
      opacity: 0; 
      bottom: -100px;
  }
  20% { 
      opacity: 1; 
      bottom: 40px;
  }
  80% { 
      opacity: 1; 
      bottom: 40px; 
  }
  100% { 
      opacity: 0; 
      bottom: -100px; 
  }
}